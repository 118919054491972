import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import facebookIcon from "@/assets/images/Facebook.svg";
import instagramIcon from "@/assets/images/Instagram.svg";
import HamburgerMenu from "@/assets/images/Menu.svg";
import Close from "@/assets/images/close.svg";
import FormModal from "@/components/modules/sorteo/FormModal";
import Seo from "../components/common/Seo";

const HeaderMobile = ({ isMenuActive, toggleMenu, toggleModal }) => {
  return (
    <header className="container py-8 lg:hidden">
      <div className="flex flex-col">
        <div className="flex content-center justify-between ">
          <div>
            <img src="/images/sorteo/sorteo_icon_hisense_primary.svg" />
          </div>
          <button onClick={toggleMenu}>
            <img src={isMenuActive ? Close : HamburgerMenu} alt="" />
          </button>
        </div>
        <div className={`absolute bottom-0 left-0 z-50 w-full bg-white top-[85px] ${!isMenuActive && "hidden"}`}>
          <div className="h-10" />
          <p className="font-bold text-center">
            <button
              onClick={() => {
                toggleMenu();
                document.getElementById("BodyTextAndIcons").scrollIntoView({ behavior: "smooth" });
              }}
            >
              ¿Cómo participar?
            </button>
          </p>
          <div className="h-10" />
          <p className="font-bold text-center">
            <a href="/documents/terminos-y-condiciones-euro-2024.pdf" target="_blank" rel="noopener noreferrer">
              Términos y Condiciones
            </a>
          </p>
          <div className="h-10" />
          <button onClick={toggleModal} className="w-full py-5 font-bold text-center bg-[#00AAA6]">
            <p className="text-white">Quiero participar</p>
          </button>
        </div>
      </div>
    </header>
  );
};

const HeaderDesktop = ({ toggleModal }) => {
  return (
    <header className="container hidden py-8 lg:block">
      <div className="flex content-center justify-between">
        <div>
          <img src="/images/sorteo/sorteo_icon_hisense_primary.svg" />
        </div>
        <div className="flex">
          <div className="flex items-center">
            <p className="text-black">
              <button
                onClick={() => {
                  document.getElementById("BodyTextAndIcons").scrollIntoView({ behavior: "smooth" });
                }}
              >
                ¿Cómo participar?
              </button>
            </p>
            <div className="w-10" />
            <p className="text-black">
              <a href="/documents/terminos-y-condiciones-euro-2024.pdf" target="_blank" rel="noopener noreferrer">
                Términos y Condiciones
              </a>
            </p>
          </div>
          <div className="w-10" />
          <button
            onClick={toggleModal}
            className="bg-[#00AAA6] rounded-full flex justify-center content-center px-5 py-2"
          >
            <p className="text-white">Quiero participar</p>
          </button>
        </div>
      </div>
    </header>
  );
};

const BodyTextAndIcons = () => {
  return (
    <>
      <div className="flex flex-col items-center lg:flex-row lg:justify-between">
        <div id="texts">
          <d className="mb-5 text-center text-[32px] font-bold lg:mb-14 lg:text-[40px]">
            <d className="text-[#00AAA6]">PARTICIPA</d> por el sorteo
          </d>
          <br />
          <div className="mb-3" />
          <d className="mb-5 text-center text-[32px] font-bold lg:mb-14 lg:text-[40px]">
            de la <d className="text-[#00AAA6]">TV MÁS GRANDE</d>
          </d>
        </div>

        <div className="h-10 lg:h-0" />

        <div id="icons">
          <div className="flex">
            <img src="/images/sorteo/sorteo_icon_hisense.png" className="w-[130px] h-[40px] lg:w-[202px] lg:h-[52px]" />
            <div className="w-[1px] h-14 bg-black mx-4" />
            <img src="/images/sorteo/sorteo_icon_euro.png" className="w-[130px] h-[40px] lg:w-[159px] lg:h-[51px]" />
          </div>
        </div>

        <div className="h-10 lg:h-0" />
      </div>

      <div className="py-4">
        <p className="text-[24px] text-center lg:text-left">1 TV 100” , 2 TV 65” y 15 Euro Packs</p>
      </div>
    </>
  );
};

const BodyCuadros = () => {
  return (
    <div className="flex flex-col items-center lg:items-start lg:justify-around lg:flex-row">
      <div className="bg-white max-w-[360px] p-5 rounded-xl min-h-[232px]">
        <p className="text-[#00AAA6] text-[32px] font-bold leading-10 pb-3 pl-4">¿CÓMO PARTICIPAR?</p>
        <p className="leading-6 text-[16px] pl-4">
          Por cada S/999 en compras de productos Hisense acumulas una opción de GANAR.
        </p>
      </div>

      <div className="h-10 lg:h-0" />

      <div className="bg-white max-w-[360px] p-5 rounded-xl min-h-[232px]">
        <p className="text-[#00AAA6] text-[32px] font-bold leading-10 pb-3 pl-4">¿DÓNDE PARTICIPAR?</p>
        <p className="leading-6 text-[16px] pl-4">
          Completa tus datos y adjunta tu boleta de comprar en el formulario.
        </p>
      </div>

      <div className="h-10 lg:h-0" />

      <div className="bg-white max-w-[360px] p-5 rounded-xl min-h-[232px]">
        <p className="text-[#00AAA6] text-[32px] font-bold leading-10 pb-3 pl-4">¿HASTA CUÁNDO PUEDO PARTICIPAR?</p>
        <p className="leading-6 text-[16px] pl-4">Participa del 05 de mayo al 15 de julio.</p>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="bg-[#00AAA6] py-10">
      <div className="container">
        <div className="flex flex-col-reverse items-center content-center justify-between lg:items-left lg:flex-row">
          <div className="flex">
            <img src="/images/sorteo/sorteo_icon_hisense_white.png" className="w-[130px] lg:w-[202px] lg:h-[52px]" />
            <div className="w-[1px] h-14 bg-white mx-4" />
            <img src="/images/sorteo/sorteo_icon_euro_white.png" className="w-[140px] lg:w-[159]" />
          </div>

          <div className="flex w-full lg:w-[100px] gap-4 mt-4 mb-5 lg:mt-6 lg:mb-0 ">
            <a
              className="flex items-center justify-center w-10 h-10 border border-white rounded-full"
              href="https://www.facebook.com/HisensePe/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook Icon" className="w-5 h-5" />
            </a>
            <a
              className="flex items-center justify-center w-10 h-10 border border-white rounded-full"
              href="https://www.instagram.com/hisensepe/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="Instagram Icon" className="w-5 h-5" />
            </a>
          </div>
        </div>
        <div className="h-[1px] bg-white my-5" />
        <p className="text-right text-white">© 2024 All rights reserved “Hisense” - Powered by 10Pearls</p>
      </div>
    </div>
  );
};

const SorteoPage = () => {
  const [isVisibleModal, setIsVisibleModal] = useState(true);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const toggleMenu = () => setIsMenuActive((prev) => !prev);
  const toggleModal = () => setIsVisibleModal((prev) => !prev);

  return (
    <div className="bg-[#F4F8F8] relative">
      <div className={`w-full ${isVisibleModal && "blur-sm"}`}>
        <HeaderMobile isMenuActive={isMenuActive} toggleMenu={toggleMenu} toggleModal={toggleModal} />
        <HeaderDesktop toggleModal={toggleModal} />

        <StaticImage
          src="../assets/images/sorteoeuro2024/soreto-euro-2024-mobile.png"
          className="lg:!hidden"
          alt=""
          quality={90}
        />
        <StaticImage
          src="../assets/images/sorteoeuro2024/soreto-euro-2024.png"
          className="!hidden lg:!block"
          alt=""
          quality={90}
        />

        <div className="container" id="BodyTextAndIcons">
          <div className="h-20" />

          <BodyTextAndIcons />

          <div className="h-20" />

          <BodyCuadros />
        </div>

        <div className="h-20" />

        <Footer />
      </div>

      {isVisibleModal ? <FormModal toggleModal={toggleModal} /> : null}
    </div>
  );
};

export const Head = () => <Seo title="Sorteo Euro 2024" />;

export default SorteoPage;
