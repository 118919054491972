import React from "react";

import Close from "@/assets/images/close.svg";

import { zodResolver } from "@hookform/resolvers/zod";
import { Link, graphql, useStaticQuery } from "gatsby";
import { FieldError, FieldErrorsImpl, Merge, SubmitHandler, useForm } from "react-hook-form";
import validator from "validator";
import { z } from "zod";

const DOCUMENT_TYPE_OPTIONS = [
  { label: "DNI", value: "DNI" },
  { label: "CE", value: "CE" },
];

const CITY_OPTIONS = [
  { label: "Amazonas", value: "Amazonas" },
  { label: "Ancash", value: "Ancash" },
  { label: "Apurimac", value: "Apurimac" },
  { label: "Arequipa", value: "Arequipa" },
  { label: "Ayacucho", value: "Ayacucho" },
  { label: "Cajamarca", value: "Cajamarca" },
  { label: "Callao", value: "Callao" },
  { label: "Cusco", value: "Cusco" },
  { label: "Huancavelica", value: "Huancavelica" },
  { label: "Huanuco", value: "Huanuco" },
  { label: "Ica", value: "Ica" },
  { label: "Junín", value: "Junín" },
  { label: "La Libertad", value: "La Libertad" },
  { label: "Lambayeque", value: "Lambayeque" },
  { label: "Lima", value: "Lima" },
  { label: "Loreto", value: "Loreto" },
  { label: "Madre de Dios", value: "Madre de Dios" },
  { label: "Moquegua", value: "Moquegua" },
  { label: "Pasco", value: "Pasco" },
  { label: "Piura", value: "Piura" },
  { label: "Puno", value: "Puno" },
  { label: "San Martín", value: "San Martín" },
  { label: "Tacna", value: "Tacna" },
  { label: "Tumbes", value: "Tumbes" },
  { label: "Ucayali", value: "Ucayali" },
];

const STORE_OPTIONS = [
  { label: "Falabella", value: "Falabella" },
  { label: "PlazaVea", value: "PlazaVea" },
  { label: "Tiendas Efe", value: "Tiendas Efe" },
  { label: "Curacao", value: "Curacao" },
  { label: "Metro", value: "Metro" },
  { label: "Oeschle", value: "Oeschle" },
  { label: "Ripley", value: "Ripley" },
  { label: "Tottus", value: "Tottus" },
  { label: "Hiraoka", value: "Hiraoka" },
  { label: "Sodimac", value: "Sodimac" },
];

const MAX_FILE_SIZE = 5000000;
const VALID_FILE_TYPES = ["application/pdf", "image/jpeg", "image/png"];

const FormSchema = z.object({
  first_name: z.string().min(1, "Campo requerido"),
  last_name: z.string().min(1, "Campo requerido"),
  document_type: z.string().min(1, "Campo requerido"),
  document_number: z.string().min(1, "Campo requerido"),
  email: z.string().email("Correo inválido").min(1, "Campo requerido"),
  phone: z
    .string()
    .min(1, "Campo requerido")
    .refine((value) => validator.isMobilePhone(value, ["es-PE"]), "Numero inválido"),
  city: z.string().min(1, "Campo requerido"),
  store: z.string().min(1, "Campo requerido"),
  amount: z.string().min(1, "Campo requerido"),
  ticket: z
    .any()
    .refine((files) => files?.length !== 0, "La boleta es requerida")
    .refine(
      (files) => VALID_FILE_TYPES.includes(files[0]?.type),
      "Solo se pueden enviar los formatos .jpg, .png y .pdf",
    )
    .refine((files) => files[0]?.size < MAX_FILE_SIZE, "El tamaño máximo es de 5MB."),
  terms: z.literal(true),
  privacy: z.literal(true),
});

type FormSchemaType = z.infer<typeof FormSchema>;

const FormModal = ({ toggleModal }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formToken
        }
      }
    }
  `);

  const { formToken } = data.site.siteMetadata;
  const directusUrl = "https://hisense-directus.whiz.pe";

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<FormSchemaType>({ resolver: zodResolver(FormSchema) });

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    try {
      const formData = new FormData();
      formData.set("file", data.ticket[0]);

      const uploadFileResponse = await fetch(`${directusUrl}/files`, {
        method: "POST",
        headers: { Authorization: `Bearer ${formToken}` },
        body: formData,
      });
      if (uploadFileResponse.status !== 200) {
        setError("root.serverError", { type: "400" });
        return;
      }
      const uploadFileResponseBody = await uploadFileResponse.json();
      const fileId = uploadFileResponseBody.data.id;

      const submitFormResponse = await fetch(`${directusUrl}/items/sorteoeuro2024_submissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${formToken}`,
        },
        body: JSON.stringify({ ...data, ticket: fileId }),
      });

      if (submitFormResponse.status !== 200) {
        setError("root.serverError", { type: "400" });
      }
    } catch (error) {
      setError("root.serverError", { type: "400" });
    }
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 top-40">
      <div className="relative flex flex-col items-center justify-center px-4">
        {/* Decoración */}
        <div className="mx-8">
          <img src="/images/sorteo/sorteo_icon_modal_header.png" width={446} height={142} />
        </div>

        {/* Decoración */}
        <div className="absolute -bottom-8 md:-bottom-[72px] z-1">
          <img src="/images/sorteo/sorteo_icon_modal_bottom.svg" width={960} height={328} />
        </div>

        {/* Formulario */}
        <div className="z-10 grid place-items-center rounded-2xl bg-[#d7daddf2] min-h-[400px] w-full lg:max-w-[900px] px-4 pt-8 pb-10 md:px-14 md:pb-14 md:pt-16 relative">
          <button onClick={toggleModal} className="absolute top-5 right-5">
            <img src={Close} />
          </button>

          {isSubmitSuccessful ? (
            <SuccessMessage />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="grid md:grid-cols-2 gap-y-4 gap-x-8">
              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="first_name" className="md:w-1/3 md:pr-6">
                  Nombres
                </label>
                <input
                  id="first_name"
                  type="text"
                  className="rounded-full h-10 md:w-2/3"
                  placeholder="Escribe tu nombre"
                  {...register("first_name")}
                />
                {errors.first_name && <ErrorMessage error={errors.first_name} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="last_name" className="md:w-1/3 md:pr-6">
                  Apellidos
                </label>
                <input
                  id="last_name"
                  type="text"
                  className="rounded-full h-10 md:w-2/3"
                  placeholder="Escribe tus apellidos"
                  {...register("last_name")}
                />
                {errors.last_name && <ErrorMessage error={errors.last_name} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="document_type" className="md:w-1/3 md:pr-6">
                  Tipo doc.
                </label>
                <select
                  id="document_type"
                  className="rounded-full h-10 md:w-2/3"
                  {...register("document_type")}
                  defaultValue={""}
                >
                  <option value="" disabled>
                    Elige
                  </option>
                  {DOCUMENT_TYPE_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.document_type && <ErrorMessage error={errors.document_type} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="document_number" className="md:w-1/3 md:pr-6">
                  Nro. doc.
                </label>
                <input
                  id="document_number"
                  type="text"
                  className="rounded-full h-10 md:w-2/3"
                  placeholder="Documento"
                  {...register("document_number")}
                />
                {errors.document_number && <ErrorMessage error={errors.document_number} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="email" className="md:w-1/3 md:pr-6">
                  Correo
                </label>
                <input
                  id="email"
                  type="email"
                  className="rounded-full h-10 md:w-2/3"
                  placeholder="ejemplo@correo.com"
                  {...register("email")}
                />
                {errors.email && <ErrorMessage error={errors.email} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="phone" className="md:w-1/3 md:pr-6">
                  Teléfono
                </label>
                <input
                  id="phon"
                  type="tel"
                  className="rounded-full h-10 md:w-2/3"
                  placeholder="Escribe tu teléfono"
                  {...register("phone")}
                />
                {errors.phone && <ErrorMessage error={errors.phone} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label htmlFor="city" className="md:w-1/3 md:pr-6">
                  Ciudad
                </label>
                <select id="city" className="rounded-full h-10 md:w-2/3" {...register("city")} defaultValue={""}>
                  <option value="" disabled>
                    Elige
                  </option>
                  {CITY_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.city && <ErrorMessage error={errors.city} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label className="md:w-1/3 md:pr-6">Tienda</label>
                <select className="rounded-full h-10 md:w-2/3" {...register("store")} defaultValue={""}>
                  <option value="" disabled>
                    Elige
                  </option>
                  {STORE_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.store && <ErrorMessage error={errors.store} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label className="md:w-1/3 md:pr-6">Monto</label>
                <input
                  type="number"
                  step=".01"
                  className="rounded-full h-10 md:w-2/3"
                  placeholder="Monto de compra"
                  {...register("amount")}
                />
                {errors.amount && <ErrorMessage error={errors.amount} />}
              </div>

              <div className="flex flex-col md:flex-row gap-y-2 flex-wrap md:items-center">
                <label className="md:w-1/3 md:pr-6" htmlFor="ticket">
                  Boleta
                </label>

                <input
                  id="file_input"
                  type="file"
                  className=" md:w-2/3 block w-full text-sm border border-gray-300 rounded-full cursor-pointer bg-white focus:outline-none file:h-10 file:rounded-full file:bg-black file:text-white file:outline-none file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold hover:file:bg-black/70"
                  {...register("ticket")}
                />

                {errors.ticket && <ErrorMessage error={errors.ticket} />}
              </div>

              <div className="space-y-4 md:space-y-2 md:col-span-2 pt-2">
                <div className="flex items-center gap-2">
                  <input id="terms" type="checkbox" className="rounded text-[#00AAA6]" {...register("terms")} />
                  <p>
                    <span>Estoy de acuerdo con los</span>{" "}
                    <a
                      className="text-[#00AAA6]"
                      href="/documents/terminos-y-condiciones-euro-2024.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos y condiciones
                    </a>
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <input id="privacy" type="checkbox" className="rounded text-[#00AAA6]" {...register("privacy")} />
                  <p>
                    <span>Acepto los</span>{" "}
                    <a
                      className="text-[#00AAA6]"
                      href="/documents/politicas-de-privacidad-euro-2024.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos de privacidad
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex justify-center pt-4 md:col-span-2">
                <button
                  type="submit"
                  className="bg-[#00AAA6] w-[216px] rounded-full h-[48px] flex items-center justify-center text-white"
                >
                  {isSubmitting ? "Enviando" : "Enviar formulario"}
                </button>
              </div>
            </form>
          )}

          {errors.root?.serverError.type === "400" && (
            <p className="mt-4 text-right text-red-500">Ha occurrido un error intentelo más tarde</p>
          )}
        </div>
      </div>
    </div>
  );
};

const SuccessMessage = () => {
  return (
    <div className="text-center max-w-sm">
      <h2 className="text-2xl font-bold mb-6 md:text-5xl">Gracias por llenar tus datos</h2>
      <p className="font-light mb-8">¡Ya estas participando para la Eurocopa 2024!</p>
      <Link to="/" className="btn">
        Visita nuestra web
      </Link>
    </div>
  );
};

const ErrorMessage = ({ error }: { error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> }) => {
  return (
    <>
      <span className="md:w-1/3" />
      <p className="-mt-1.5 md:w-2/3 text-xs text-red-500">{error.message as string}</p>
    </>
  );
};

export default FormModal;
